import React from 'react'
import Layout from '../components/layout'
import Header from '../components/Header'
import { Helmet } from 'react-helmet'
import Sidebar from '../components/Sidebar/Sidebar'

const OilField = () => (
    <Layout>
      <Helmet>
          <title>IN-ECO Oil Field Chemicals</title>
      </Helmet>
      <Header
      styleName='header header-oil'
      title={<span className="text-header-title">Oil field chemicals</span>}/>
      <div className="columns">
        <div className="column content">
          <div className="content-text">Modern technology IN - ECO ™ for oil and gas industry allow to optimize the use of resources, reduce the environmental burden, ensure safety and prolong the service life of equipment and minimize operating costs.<br/>
            <br/>
                The company offers a wide range of products and technological solutions for the treatment of oil field water, protection of oil-field and drilling equipment, preparation and transportation of oil:</div>
          <ul>
              <li className="content-text">Corrosion inhibitors for primary processing of crude oil</li>
              <li className="content-text">Defoamers and other agents for oil–water and gas-oil separation</li>
              <li className="content-text">Scavenger of the aggressive gases (CO<sub>2</sub> and H<sub>2</sub>S)</li>
              <li className="content-text">Corrosion inhibitors for transportation of crude oil</li>
              <li className="content-text">High performance biocides and slimicides</li>
              <li className="content-text">Scale inhibitors</li>
              <li className="content-text">Demulsifiers</li>
          </ul>
        </div>
        <Sidebar
          analysis='Questionnaires'
          boiler='Boiler'
          cooling='Cooling'
          ro='RO'
          broshure='IN-ECO brochure'
          certificates='Certificates'
        />
      </div>
    </Layout>
)

export default OilField